<script setup lang="ts">
    import type { Asset } from '~/@types/cms';
    import { useMediaQuery } from '~/composables/media-query';

    export interface VideoPreviewProps {
        code: string;
        previewImage?: Asset;
        fullbleed?: boolean;
        iconSize?: 'small' | 'medium';
    }

    const props = withDefaults(defineProps<VideoPreviewProps>(), {
        iconSize: 'medium',
    });

    const isMobile = ref(false);
    useMediaQuery([
        {
            query: defaultQueries.max.mobile,
            ref: isMobile,
        },
    ]);

    const sizes = [
        { size: '120', sizeThumbnail: 'default' }, // 120x90
        { size: '320', sizeThumbnail: 'mqdefault' }, // 320x180
        { size: '480', sizeThumbnail: 'hqdefault' }, // 480x360
        { size: '640', sizeThumbnail: 'sddefault' }, // 640x480
        { size: '1280', sizeThumbnail: 'maxresdefault' }, // 1280x720 (Unscaled thumbnail resolution)
    ];

    const getSrcset = (format: 'jpg' | 'webp' = 'jpg') =>
        sizes
            .map((size) => `https://i.ytimg.com/${format === 'webp' ? 'vi_webp' : 'vi'}/${props.code}/${size.sizeThumbnail}.${format} ${size.size}w`)
            .join(', ');
</script>

<template>
    <div class="atm-video-preview relative h-full w-full">
        <span class="sr-only">{{ $t('cta.playVideo') }}</span>
        <div
            class="absolute inset-0 top-0 z-10 flex h-full items-center justify-center bg-black/[.35]"
            aria-hidden="true">
            <woom-icon-play
                :class="[
                    'fill-woom-white',
                    iconSize === 'medium' ? (fullbleed ? 'md:w-24' : 'md:w-16') : '',
                    {
                        'w-8': iconSize === 'small',
                        'w-12': iconSize === 'medium',
                    },
                ]" />
        </div>
        <picture
            v-if="!previewImage"
            :class="{ 'lg:inset-0 lg:h-full lg:w-full': fullbleed }"
            role="presentation">
            <source
                type="image/webp"
                :srcset="getSrcset('webp')" />
            <img
                :srcset="getSrcset()"
                alt=""
                class="h-full w-full object-cover object-center" />
        </picture>
        <client-only v-else>
            <atm-image
                :class="{ 'lg:inset-0 lg:h-full lg:w-full': fullbleed }"
                role="presentation"
                cover
                :preset="isMobile ? '2to3' : '16to9'"
                :data="previewImage"
                :sizes="fullbleed ? 'sm:100vw md:1264px lg:1920px' : 'sm:100vw md:1024px lg:1264px'" />
        </client-only>
    </div>
</template>
